var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vs-card',{staticClass:"emailCard"},[_c('vs-form',[_c('vs-row',{attrs:{"vs-align":"flex-start","vs-justify":"left","vs-w":"12"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"left","vs-w":"12"}},[_c('div',{staticClass:"w-full m-5"},[_c('label',{staticClass:"label",attrs:{"for":"SubjectLine"}},[_vm._v("Subject Line")]),_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3|max:200'),expression:"'required|min:3|max:200'"}],staticClass:"col-sm-3",attrs:{"id":"SubjectLine","data-vv-validate-on":"blur","name":"subject"},model:{value:(_vm.pages.subject),callback:function ($$v) {_vm.$set(_vm.pages, "subject", $$v)},expression:"pages.subject"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("subject")))])],1)])],1)],1),_c('vs-row',{attrs:{"vs-align":"flex-start","vs-justify":"left","vs-w":"12"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"left","vs-w":"12"}},[_c('div',{staticClass:"editEmail w-full m-5"},[_c('vs-label',{staticClass:"label"},[_vm._v("Email Content")])],1)])],1),_c('vs-row',{attrs:{"vs-align":"flex-start","vs-justify":"left","vs-w":"12"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"left","vs-w":"12"}},[_c('div',{staticClass:"w-full m-5"},[_c('vs-label',{staticClass:"label"},[_vm._v("Available Linked Content")]),_c('div',{staticClass:"linkedContent"},[_c('ul',[_c('li',{staticClass:"keywords"},[_c('span',{pre:true},[_vm._v("name")])]),_c('li',{staticClass:"keywords"},[_c('span',{pre:true},[_vm._v("projectName")])]),(
          _vm.pages.slug === 'forgot-password' ||
          _vm.pages.slug === 'reset-password' ||
          _vm.pages.slug === 'verification-email'
        )?_c('li',{staticClass:"keywords"},[_c('span',{pre:true},[_vm._v("link")])]):_vm._e(),(_vm.pages.slug === 'sales-advice')?_c('li',{staticClass:"keywords"},[_c('span',{pre:true},[_vm._v("role")])]):_vm._e(),(
          _vm.pages.slug === 'new-admin-created' ||
          _vm.pages.slug === 'admin-change-password'
        )?_c('li',{staticClass:"keywords"},[_c('span',{pre:true},[_vm._v("password")])]):_vm._e(),(
          _vm.pages.slug === 'new-admin-created' ||
          _vm.pages.slug === 'admin-change-password'
        )?_c('li',{staticClass:"keywords"},[_c('span',{pre:true},[_vm._v("login")])]):_vm._e()])])],1)])],1),_c('vs-row',{attrs:{"vs-align":"flex-start","vs-justify":"left","vs-w":"12"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"left","vs-w":"12"}},[_c('div',{staticClass:"editEmail w-full m-5"},[_c('vs-label',{staticClass:"label"},[_vm._v(" Body Content")]),_c('quillEditor',{attrs:{"pages":_vm.pages}})],1)])],1),_c('vs-divider'),_c('vs-row',{attrs:{"vs-justify":"flex-end","vs-w":"12"}},[_c('vs-button',{staticClass:"mr-6",attrs:{"color":"danger","type":"filled"},on:{"click":function($event){return _vm.$router.replace('/admin/email-template')}}},[_vm._v("Cancel")]),_c('vs-button',{attrs:{"align":"left"},on:{"click":_vm.saveEmailDetails}},[_vm._v("Save")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }