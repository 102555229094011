<template>
  <vs-card class="emailCard">
    <!-- <div slot="header">
      <feather-icon icon="ChevronLeftIcon" svgClasses="h-4 w-4" class="mr-2 cursor-pointer" @click="$router.push({name: 'email-template'})"></feather-icon>
      <vs-label class="font-bold">Email List</vs-label>
    </div> -->
    <vs-form>
      <vs-row vs-align="flex-start" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5">
          <label class="label" for="SubjectLine">Subject Line</label>
          <vs-input id="SubjectLine" v-validate="'required|min:3|max:200'" class="col-sm-3" data-vv-validate-on="blur"
                    name="subject" v-model="pages.subject">
          </vs-input>
          <span class="error">{{ errors.first("subject") }}</span>
          </div>
        </vs-col>
      </vs-row>
    </vs-form>

    <vs-row vs-align="flex-start" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
      <div class="editEmail w-full m-5">
      <vs-label class="label">Email Content</vs-label>
    </div>
      </vs-col>
    </vs-row>

    <vs-row vs-align="flex-start" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <div class="w-full m-5">
        <vs-label class="label">Available Linked Content</vs-label>

    <div class="linkedContent">
      <ul>
        <li class="keywords"><span v-pre>name</span></li>
        <li class="keywords"><span v-pre>projectName</span></li>
        <li
          class="keywords"
          v-if="
            pages.slug === 'forgot-password' ||
            pages.slug === 'reset-password' ||
            pages.slug === 'verification-email'
          "
        >
          <span v-pre>link</span>
        </li>
        <li class="keywords" v-if="pages.slug === 'sales-advice'">
          <span v-pre>role</span>
        </li>
        <li
          class="keywords"
          v-if="
            pages.slug === 'new-admin-created' ||
            pages.slug === 'admin-change-password'
          "
        >
          <span v-pre>password</span>
        </li>
        <li
          class="keywords"
          v-if="
            pages.slug === 'new-admin-created' ||
            pages.slug === 'admin-change-password'
          "
        >
          <span v-pre>login</span>
        </li>
      </ul>
    </div>
        </div>

      </vs-col>
    </vs-row>

    <vs-row vs-align="flex-start" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">

      <div class="editEmail w-full m-5">
      <vs-label class="label"> Body Content</vs-label>
      <quillEditor :pages="pages"></quillEditor>
    </div>
      </vs-col>
    </vs-row>


    <vs-divider></vs-divider>

    <vs-row vs-justify="flex-end" vs-w="12">
      <vs-button class="mr-6" color="danger" type="filled" @click="$router.replace('/admin/email-template')">Cancel</vs-button>
      <vs-button @click="saveEmailDetails" align="left">Save</vs-button>
    </vs-row>

  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import quillEditor from "../../Editor";
import moment from "moment";

export default {
  name: "emailViewEditDetail",
  components: {
    quillEditor,
  },
  data: () => ({
    pages: {
      content: "",
      subject: "",
    },
  }),
  methods: {
    ...mapActions("email", ["fetchEmailDetails", "updateEmailDetails"]),
    getEmailDetail(id) {
      let self = this;
      this.fetchEmailDetails(id).then((res) => {
        this.pages.content = res.data.data.content;
        this.pages.subject = res.data.data.subject;
        this.pages = res.data.data;
      });
    },
    saveEmailDetails() {
      this.$validator.validateAll().then((success) => {
        if (!success) {
          return false;
        }
        let id = this.$route.params.id;
        let info = this.pages;
        this.updateEmailDetails({ id, info }).then((res) => {
          this.$vs.notify({
            subject: "Update Page Content",
            text: res.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
        });
      });
    },
  },
  created() {
    let pageId = this.$route.params.id;
    this.getEmailDetail(pageId);
  },
};
</script>