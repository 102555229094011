<template>
    <quill-editor
                  v-model="pages.content"
                  >
    </quill-editor>
</template>

<script>

import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: { quillEditor },
  props: ["pages"],
  data() {
    return {
        editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'link', 'blockquote', 'code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'font': ['Serif'] }],
          ],
        }
    }
    }
  }
};
</script>
